import React from 'react'
import Accreditations from '../accreditations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'

const Introduction = () => (
  <div className="page">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-lg-8">
          <h2>
            We at Maxpower Limited carry out all types of electrical works and
            repair services for domestic, commercial and industrial sectors
          </h2>

          <p>
            We are fully accredited Part P and insured via government schemes.
          </p>

          <p>
            Established in 2015 we provide all types of Electrical Works across{' '}
            <Link
              to="/electrical-testing-services-in-norfolk/"
              title="Maxpower Electrical testing services in Norfolk"
            >
              Norfolk
            </Link>
            ,{' '}
            <Link
              to="/electrical-testing-services-in-suffolk/"
              title="Maxpower Electrical testing services in Suffolk"
            >
              Suffolk
            </Link>
            ,{' '}
            <Link
              to="/electrical-testing-services-in-essex/"
              title="Maxpower Electrical testing services in Essex"
            >
              Essex
            </Link>{' '}
            and{' '}
            <Link
              to="/electrical-testing-services-in-london/"
              title="Maxpower Electrical testing services in London"
            >
              London
            </Link>
            , whilst maintaining local, competitive rates.
          </p>

          <p>
            Clients are comprised of property owners, care homes, local and
            public authorities, schools, universities, and colleges together
            with a wide variety of industrial, leisure and commercial / FM
            organisations.
          </p>

          <p>
            Many ongoing relationships and repeat business have consolidated
            every aspect of the services provided.
          </p>

          <p>
            Our team consists of highly skilled and fully qualified technicians
            and are proficient in the rectification and repair of any equipment.
          </p>

          <Accreditations />
        </div>
        <div className="d-none d-lg-block col-lg-3 offset-lg-1">
          <p className="contact-sidebar">
            <span className="tagline">Electrical testing services:</span>
            <span className="phone">London: 020 3633 0956</span>
            <br />
            <span className="phone">Thetford: 01842 558212</span>
          </p>

          <h3>Specialist Sectors</h3>
          <ul className="service-list">
            <li>Education</li>
            <li>Retail</li>
            <li>Care homes</li>
            <li>Health &amp; Leisure</li>
            <li>Facilities Partners</li>
            <li>Social Housing</li>
            <li>Office &amp; Factories</li>
            <li>Warehousing</li>
            <li>Pubs &amp; Clubs</li>
            <li>Vets</li>
          </ul>

          <p className="social-icons">
            <a
              href="https://www.facebook.com/Maxpower-Limited-567584623396346/"
              title="Like Maxpower Electrical Compliance on Facebook"
              className="facebook"
            >
              <FontAwesomeIcon icon={['fab', 'facebook-square']} />
            </a>
            <a
              href="https://www.linkedin.com/in/barrie-mcguire-01b10a119/"
              title="Follow Maxpower Electrical Compliance on Linkedin"
              className="linkedin"
            >
              <FontAwesomeIcon icon={['fab', 'linkedin']} />
            </a>
            <a
              href="https://twitter.com/maxpowerlimited"
              title="Follow Maxpower Electrical Compliance on Twitter"
              className="twitter"
            >
              <FontAwesomeIcon icon={['fab', 'twitter-square']} />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Introduction
