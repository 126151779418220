import React from 'react'

import Layout from '../layouts'
import Feature from '../components/Feature'
import Introduction from '../components/Introduction'
import Services from '../components/Services'
import Testimonials from '../components/Testimonials'

const IndexPage = props => (
  <Layout {...props}>
    <Feature />

    <Introduction />

    <Services />

    <Testimonials />
  </Layout>
)

export default IndexPage
