import React from 'react'
import Link from 'gatsby-link'

import './services.css'

const Services = () => (
  <div
    style={{
      background: '#F5F5F5',
      marginTop: '1.45rem',
      marginBottom: '1.45rem',
      padding: '80px 0 40px',
    }}
  >
    <div className="services container">
      <div className="row">
        <div className="col text-center">
          <h2 title="Electrical Testing Services">
            Electrical Testing Services
          </h2>
        </div>
      </div>

      <div className="row" style={{ marginTop: '50px' }}>
        <div className="col-md-6 col-lg-3 service">
          <Link
            to="/fixed-wire-testing/"
            title="(ECIR) Fixed Wire Testing services"
          >
            <h3>Fixed Wire testing</h3>
          </Link>

          <Link
            to="/fixed-wire-testing/"
            title="(ECIR) Fixed Wire Testing services"
          >
            <div className="img-container consumer">
              <span>(ECIR) Fixed Wire Testing services</span>
            </div>
          </Link>

          <p>
            Fixed Wire Testing is the report and inspection of electrical
            systems in a premises in regards to safety.{' '}
            <Link
              to="/fixed-wire-testing/"
              title="(ECIR) Fixed Wire Testing services"
            >
              Read more...
            </Link>
          </p>
        </div>

        <div className="col-md-6 col-lg-3 service">
          <Link
            to="/emergency-light-testing/"
            title="Emergency Light Testing services"
          >
            <h3>Emergency light testing</h3>
          </Link>

          <Link
            to="/emergency-light-testing/"
            title="Emergency Light Testing services"
          >
            <div className="img-container emergency">
              <span>Emergency Light Testing services</span>
            </div>
          </Link>

          <p>
            Emergency lighting is lighting for an emergency situation when the
            main power supply is cut and any normal illumination fails.{' '}
            <Link
              to="/emergency-light-testing/"
              title="Emergency Light Testing services"
            >
              Read more...
            </Link>
          </p>
        </div>

        <div className="col-md-6 col-lg-3 service">
          <Link to="/pat-testing/" title="PAT Testing services">
            <h3>PAT testing</h3>
          </Link>

          <Link to="/pat-testing/" title="PAT Testing services">
            <div className="img-container pat-testing">
              <span>PAT Testing services</span>
            </div>
          </Link>

          <p>
            Portable appliance testing, commonly known as PAT testing, is a way
            of checking your electrical equipment to see if it is safe.{' '}
            <Link to="/pat-testing/" title="PAT Testing services">
              Read more...
            </Link>
          </p>
        </div>

        <div className="col-md-6 col-lg-3 service">
          <Link to="/remedial-repairs/" title="Remedial repairs services">
            <h3>Remedial repairs</h3>
          </Link>

          <Link to="/remedial-repairs/" title="Remedial repairs services">
            <div className="img-container electrician">
              <span>Remedial repairs services</span>
            </div>
          </Link>

          <p>
            Remedial works are required to satisfy the duties imposed on duty
            holders by the Health and Safety at Work Act.{' '}
            <Link to="/remedial-repairs/" title="Remedial repairs services">
              Read more...
            </Link>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Services
